<template>
  <div style="height: inherit">
    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <h1>{{ $t("buyProducts") }}</h1>
              <div class="search-results"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />
    <!-- Prodcuts -->
    <section class="d-flex shop-container">
      <b-card
        v-for="product in products"
        :key="product.id"
        class="shop-card"
        no-body
      >
        <div class="item-img text-left p-1 bg-white">
          <b-link
            :to="{
              name: 'apps-e-commerce-product-details',
              params: { slug: product.slug },
            }"
          >
            <component
              :is="product.pictureUrl"
              :alt="`${product.name}`"
              style="max-width: 300px"
            />
            <b-img
              v-if="!product.pictureUrl"
              :src="require('@/assets/images/logo/logo.png')"
              :alt="`${product.name}`"
              style="width: 150px"
            />
          </b-link>
        </div>

        <!-- Product Details -->
        <b-card-body>
          <h6 class="item-name">
            <b-link
              class="text-body h2"
              :to="{
                name: 'apps-e-commerce-product-details',
                params: { slug: product.slug },
              }"
            >
              {{ product.saleitemname }}
            </b-link>
            <b-card-text class="item-company">
              <b-link class="ml-25"> </b-link>
            </b-card-text>
          </h6>
          <div class="item-wrapper">
            <div class="item-rating"></div>
            <div>
              <h6 class="item-price">
                ₺{{ Math.floor(parseFloat(product.orjprice)) }}
              </h6>
            </div>
          </div>
          <b-card-text class="item-description">
            {{ product.description }}
          </b-card-text>
        </b-card-body>
        <div class="px-2 pb-2">
          <b-button
            variant="primary"
            tag="a"
            class="btn-cart w-100"
            @click="handleCartActionClick(product)"
          >
            <feather-icon icon="ShoppingCartIcon" class="mr-50" />
            <span>{{
              product.isInCart ? "View In Cart" : $t("addToCart")
            }}</span>
          </b-button>
          <!-- Product Actions -->
        </div>
      </b-card>
    </section>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { watch } from "@vue/composition-api";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import {
  useShopFiltersSortingAndPagination,
  useShopUi,
  useShopRemoteData,
} from "./useShop";
import { useEcommerceUi } from "./useCart";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,

    // SFC
  },
  setup() {
    const { filters, filterOptions, sortBy, sortByOptions } =
      useShopFiltersSortingAndPagination();

    const { handleCartActionClick } = useEcommerceUi();

    const { itemView, itemViewOptions, totalProducts } = useShopUi();

    const { products, fetchProducts } = useShopRemoteData();

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchShopProducts = () => {
      fetchProducts().then((response) => {
        products.value = response;
        totalProducts.value = response.length;
      });
    };

    fetchShopProducts();

    watch(
      [filters, sortBy],
      () => {
        fetchShopProducts();
      },
      {
        deep: true,
      }
    );

    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      handleCartActionClick,

      // useShopRemoteData
      products,
      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.shop-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  .shop-card {
    width: calc(25% - 1rem);
    max-width: 100%;
    @media (max-width: 623px) {
      width: 100%;
    }
    @media (min-width: 624px) and (max-width: 1346px) {
      width: calc(50% - 1rem);
    }
  }
}
</style>
